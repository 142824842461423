import wrapper from 'redux/store'
import { withRedis } from 'api/callConstructor'
import HeadTags from 'components/app/HeadTags/HeadTags'
import {
  getAssetPagesCall,
  getAvgChangesReturnCall,
  getHomeCall,
  getIndustriesAllCall,
  getInvestmentsCall,
  getLimitedArticlesCall,
  getMoneyChatCall,
  getSponsoredArticleCall
} from 'api/calls'
import { redis } from 'utils/redis'
import MoneyChat from 'modules/moneyChat/MoneyChat/MoneyChat'
import { useSelector } from 'react-redux'
import { moveItemsToFront } from 'modules/home/HomePage/homePageUtils'

const PlatformsTabContainer = props => {
  const { banners } = useSelector(({ app }) => app)

  const updatedProps = {
    ...props,
    banners
  }
  return (
    <>
      <HeadTags
        title="Chat"
        description="MoneyChat AI finds your investment matches. Simply ask a question and discover your options."
        moneyChat
      />
      <MoneyChat {...updatedProps} />
    </>
  )
}

export const getStaticProps = wrapper.getStaticProps(async () => {
  const moneyChat = await withRedis(redis, getMoneyChatCall(), '/')

  const assetPagesSlugs = await withRedis(redis, getAssetPagesCall(), '/')
  const investments = await withRedis(redis, getInvestmentsCall(), '/')

  const premiumData = moveItemsToFront(investments.result, ['public', 'robinhood', 'ark7'])

  const sortPremium = premiumData
    ? premiumData.map(
        ({
          logo,
          name,
          averageRating,
          industry,
          industries,
          actualReturn,
          slug,
          collapsedDescription,
          minInvestment,
          target,
          dataIndustry
        }) => ({
          logo,
          name,
          averageRating,
          industry,
          industries,
          actualReturn,
          slug,
          collapsedDescription,
          minInvestment,
          target,
          dataIndustry
        })
      )
    : []

  const topReturnInvest = investments.result.map(
    ({ id, industries, logo, slug, objectives, newRisks, timeframeNumber, investors, shortDescription }) => ({
      id,
      industries,
      logo,
      slug,
      objectives,
      newRisks,
      timeframeNumber,
      investors,
      shortDescription
    })
  )

  const articlespickFields =
    'pick-fields=[*]slug&pick-fields=[*]title&pick-fields=[*]logo&pick-fields=[*]cardColor&pick-fields=[*]published&pick-fields=[*]industries&pick-fields=[*]shortDescription&pick-fields=[*]logoThumbnail'

  const resultSponsored = await withRedis(redis, getSponsoredArticleCall(articlespickFields), '/')

  const resultLimited = await withRedis(redis, getLimitedArticlesCall(`&${articlespickFields}&_limit=${16}`), '/')

  const industriesAll = await withRedis(redis, getIndustriesAllCall(), '/')

  const slugIndustries = [
    ...new Set(industriesAll.result.map(({ dataSlug }) => `${dataSlug ? `slugs=${dataSlug}` : ''}`))
  ].join('&')

  const resultAvg = await withRedis(
    redis,
    getAvgChangesReturnCall(slugIndustries.replace('&slugs=&slugs=', '&slugs=')),
    '/trending'
  )

  const homePage = await withRedis(redis, getHomeCall(), '/')

  return {
    props: {
      moneyChat: moneyChat.success ? moneyChat.result : {},
      lastUpdate: Date.now(),
      assetPagesSlugs: assetPagesSlugs.result,
      content: homePage.success ? homePage.result : {},
      premiumPlatform: sortPremium.slice(0, 5),
      topInvestAndQuiz: topReturnInvest || [],
      sponsor: resultSponsored.success ? resultSponsored.result : [],
      articles: resultLimited.success ? resultLimited.result : [],
      industriesAll: industriesAll.result || [],
      industriesAvgAll: resultAvg.success ? resultAvg.result : {}
    },
    revalidate: 3600 // 1 hour
  }
})

export default PlatformsTabContainer
