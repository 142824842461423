import { Container, H1, H4, Icon, Input, P2, useWindowSize } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'
import { useEffect, useState, useCallback, useRef, useMemo } from 'react'

import useTracker from 'hooks/useTracker'
import FullQuiz from 'modules/home/HomePage/FullQuiz/FullQuizContainer'
import HeaderBanner from 'components/banners/HeaderBanner/HeaderBanner'
import MobBanner from 'components/banners/MobBanner/MobBanner'
import Latest from 'modules/home/HomePage/Latest/Latest'
import QuizYesNo from 'modules/home/HomePage/QuizYesNo/QuizYesNoContainer'
import MmTrend from 'modules/home/HomePage/MmTrend/MmTrend'
import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import LockComponent from 'modules/home/HomePage/FullQuiz/LockComponent/LockComponent'
import SubForm from 'modules/discover/PlatformPage/SubForm/SubForm'
import { getCdnImg } from 'utils/getters'
import { findBanner } from 'utils/checkers'
import styles from './MoneyChat.module.scss'

const MoneyChat = ({
  assetPagesSlugs,
  banners,
  industriesAll,
  content: { webparts },
  topInvestAndQuiz,
  premiumPlatform,
  industriesAvgAll,
  sponsor,
  articles,
  moneyChat: { title, description, popularQuestions }
}) => {
  const [input, setInput] = useState('')
  const [openIframe, setOpenIframe] = useState('')
  const [heightIframe, setHeightIframe] = useState(0)
  const headerBack = useRef(null)
  const iframeRef = useRef(null)
  const [banner, setBanner] = useState({ header: {}, inText: {}, rightRail: {}, platformBanner: {} })
  const [blur, setBlur] = useState(false)

  const { handleUseTracker } = useTracker()
  const { screenWidth } = useWindowSize()
  const data = useMemo(
    () => ({
      latest: webparts.find(({ type }) => type === 'latest'),
      quiz: webparts.find(({ type }) => type === 'quiz-yes-no'),
      trends: webparts.find(({ type }) => type === 'mm-trends')
    }),
    [webparts]
  )

  useEffect(() => {
    // Find banner for this Page
    const foundBanner = findBanner(banners, 'trending')
    if (foundBanner) {
      setBanner(foundBanner)
      if (Object.keys(foundBanner?.header).length) handleUseTracker('BannerInView', 'header', '/trending')
      if (Object.keys(foundBanner?.platformBanner).length)
        handleUseTracker('BannerInView', 'platformBanner', '/trending')
      if (Object.keys(foundBanner?.rightRail).length) handleUseTracker('BannerInView', 'rightRail', '/trending')
    }
  }, [banners, handleUseTracker])

  const handleSetIframe = text => {
    if (text.length > 5) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      handleUseTracker('MoneychatQuestionSubmit', `${text}`, `${text}`)
      setOpenIframe(text)
    }
  }

  const handleQuestionPopular = text => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    setInput(text)
  }

  // prevent empty submissions
  const handleEnter = event => {
    if (event.key === 'Enter' && input.length > 5) {
      handleSetIframe(input)
    }
  }

  const handleBackToMM = () => {
    setOpenIframe('')
    setInput('')
  }

  const handleIframeMessage = useCallback(
    event => {
      // Check if the event is for the 'chatHeight' action
      if (event.data.action === 'chatHeight') {
        // Get the height value from the message data
        const chatHeight = event.data.height

        setHeightIframe(chatHeight)
      }
      if (event.data.action === 'chatScroll') {
        // Get the height value from the message data
        const chatScroll = event.data.scroll
        if (chatScroll)
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
      }

      if (event.data.action === 'keyBoardFocus') {
        // Get the height value from the message data
        const activeKeyBoard = event.data.scroll
        if (activeKeyBoard && screenWidth < 769) {
          setTimeout(
            () =>
              window.scrollTo({
                top: 250,
                behavior: 'smooth'
              }),
            500
          )
        }
      }
      if (event.data.action === 'keyBoardBlur') {
        // Get the height value from the message data
        const activeKeyBoard = event.data.scroll
        if (activeKeyBoard && screenWidth < 769)
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
      }
    },
    [screenWidth]
  )

  useEffect(() => {
    // Add event listener for postMessage events from the iframe
    window.addEventListener('message', handleIframeMessage)

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [handleIframeMessage])

  const handleInputBlur = () => {
    if (screenWidth < 769)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
  }

  const handleInputFocus = () => {
    if (screenWidth < 769)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
  }

  return (
    <section className={`${styles.MoneyChat} ${openIframe ? styles.OpenIframe : ''}`}>
      {!openIframe ? (
        <Container width={960} className={styles.TopBlock}>
          <H1 type="heading" weight="bold" className={styles.Title}>
            {title}
          </H1>
          <H4 weight="normal" className={styles.Description}>
            {description}
          </H4>
          <div className={styles.InputContainer}>
            <Input
              value={input}
              placeholder="Your question here"
              onKeyDown={handleEnter}
              onBlur={handleInputBlur}
              onFocus={handleInputFocus}
              onChange={event => setInput(event.target.value)}
            />
            <button type="submit" onClick={() => handleSetIframe(input)} className={styles.SubmitBtn}>
              {/* <img src="https://assets.moneymade.io/images/home/telegram.svg" alt="telegram" />
               */}
              <ImgLazy
                src={getCdnImg('https://assets.moneymade.io/images/home/telegram.svg', 17)}
                alt="telegram"
                width="100%"
                height="100%"
              />
            </button>
          </div>
        </Container>
      ) : null}

      {popularQuestions && !openIframe ? (
        <div className={styles.PopularQuestions}>
          <H4 weight="normal">Or try some popular questions:</H4>
          <div width={960} className={styles.Questions}>
            {popularQuestions.map(({ text, icon }, index) => (
              <div aria-hidden key={index} className={styles.Question} onClick={() => handleQuestionPopular(text)}>
                <i className={`fa-solid ${icon}`} />
                <P2>{text}</P2>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {openIframe ? (
        <Container width={687} className={styles.IframeWrapper}>
          <button type="button" onClick={handleBackToMM} className={styles.BackButton} ref={headerBack}>
            <Icon icon="icon-point-left" size={16} />
            <H4 weight="semibold">MoneyChat</H4>
          </button>

          <iframe
            height={heightIframe}
            ref={iframeRef}
            width="100%"
            title="Money Chat"
            frameBorder="0"
            scrolling="no"
            allow="encrypted-media"
            src={`https://moneychat.moneymade.io?question=${openIframe}`}
            className={styles.Iframe}
          />
        </Container>
      ) : null}

      <div className={styles.ContentNew}>
        <FullQuiz
          assetPagesSlugs={assetPagesSlugs}
          industriesAll={industriesAll}
          investments={topInvestAndQuiz}
          handleBlur={() => setBlur(true)}
        />
        <LockComponent lock={blur} className={styles.Data}>
          {Object.keys(banner?.header).length ? (
            screenWidth > 695 ? (
              <HeaderBanner {...banner.header} />
            ) : (
              <MobBanner {...banner.header} header />
            )
          ) : null}

          <MmTrend hidetitle {...data?.trends} investments={premiumPlatform} industriesAvgAll={industriesAvgAll} />
          <Container width={960}>
            <SubForm />
          </Container>
          <Latest {...data?.latest} sponsor={sponsor} banner={banner.platformBanner} articles={articles} />
          <QuizYesNo {...data?.quiz} />
        </LockComponent>
      </div>
    </section>
  )
}

MoneyChat.propTypes = {
  moneyChat: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    popularQuestions: PropTypes.arrayOf(PropTypes.object)
  }),
  content: PropTypes.shape({
    webparts: PropTypes.arrayOf(PropTypes.object)
  }),
  assetPagesSlugs: PropTypes.arrayOf(PropTypes.string),
  industriesAll: PropTypes.arrayOf(PropTypes.object),
  topInvestAndQuiz: PropTypes.arrayOf(PropTypes.object),
  premiumPlatform: PropTypes.arrayOf(PropTypes.object),
  industriesAvgAll: PropTypes.shape({}),
  sponsor: PropTypes.arrayOf(PropTypes.object),
  banners: PropTypes.arrayOf(PropTypes.object),
  articles: PropTypes.arrayOf(PropTypes.object)
}

MoneyChat.defaultProps = {
  moneyChat: {
    title: '',
    description: '',
    popularQuestions: []
  },
  content: {
    webparts: []
  },
  assetPagesSlugs: [],
  industriesAll: [],
  topInvestAndQuiz: [],
  premiumPlatform: [],
  industriesAvgAll: {},
  sponsor: [],
  banners: [],
  articles: []
}

export default MoneyChat
