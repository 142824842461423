import React, { useEffect, useState } from 'react'
import { H2, H3, Input, MainButton, useLoader } from '@moneymade/moneymade-ui'

import { subscribeNewsLetterCall } from 'api/calls'
import useTracker from 'hooks/useTracker'
import styles from './SubForm.module.scss'

const SubForm = () => {
  const { handleUseTracker } = useTracker()
  const { loaderStatus, setLoader } = useLoader(false)

  const [emailInput, setEmailInput] = useState('')
  const [formStatus, setFormStatus] = useState('')
  const [isFormDisabled, setFormDisabled] = useState(false)

  useEffect(() => {
    const regEx =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (regEx.test(emailInput)) setFormDisabled(false)
    else setFormDisabled(true)
  }, [emailInput, setFormDisabled])

  const handleSubscribe = async () => {
    // Disable form to prevent email changes
    setFormDisabled(true)
    setLoader(true)

    // Send events
    handleUseTracker('User', 'Newsletter', 'Newsletter')

    // Send email to the API
    const { success, message } = await subscribeNewsLetterCall({ email: emailInput, sendEmail: true })()

    // Processing statuses
    if (success) setFormStatus('Thank you. Successfully submitted')
    else if (message === 'User alredy subscribed!') setFormStatus('Already subscribed.')
    else {
      setFormStatus('Something went wrong. Please try again.')
      setFormDisabled(false)
    }

    setLoader(false)
  }

  return (
    <div className={styles.SubForm}>
      <img src="/images/banner.png" alt="banner " className={styles.HeaderImg} />
      <div>
        <H2 weight="semibold" className={styles.HeaderTitle}>
          <span>Slash Your Taxes </span>
        </H2>
        <H3 weight="light" className={styles.SecondaryTitle}>
          7 Tax Hacks to Save You Thousand!
        </H3>
      </div>
      <div>
        <Input
          value={emailInput}
          onChange={({ target: { value } }) => setEmailInput(value)}
          msg={formStatus}
          shape="squared"
          placeholder="Enter email address"
          className={styles.InputForm}
        />

        <MainButton
          color="dark"
          shape="squared"
          weight="normal"
          fontType="main"
          onClick={event => {
            if (!isFormDisabled) handleSubscribe(event)
          }}
          // disabled={isFormDisabled}
          className={styles.Button}
        >
          {loaderStatus ? 'Sending' : 'Download Your Free Tax Tips'}
        </MainButton>
      </div>
    </div>
  )
}
export default SubForm
